<template>
	<div class="container" ref="orderScreenshotRef">
		<ByDialog
			width="90%"
			v-model="isOpenFinishModal"
			title="您的訂單已成功送出到店家！"
			@cancel="closeFinishModal"
			@confirm="closeFinishModal"
			:noCancelButton="true"
		>
		</ByDialog>
    <ByDialog
        width="90%"
        v-model="isOpenFailModal"
        title="付款失敗！將重新回到點餐頁面。"
        @cancel="closeFailModal"
        @confirm="closeFailModal"
        :noCancelButton="true"
    >
    </ByDialog>
		<div class="center-page-minus">
			<span class="remind" v-if="canTake !== 'del' && !loading"
				>請保留此畫面，並於取餐時出示</span
			>
			<div
				class="num-wrap"
				v-if="canTake === 'stay' && !loading"
				:key="refreshKey"
			>
				<div class="num">
					<div class="title">取餐號碼</div>
					<div class="number">{{ data.takeNumber }}</div>
				</div>
				<div class="num">
					<div class="title">當前號碼</div>
					<div class="number">{{ takeNumber }}</div>
				</div>
			</div>

			<div class="num-wrap" v-else :key="refreshKey">
				<div class="num">
					<div class="title">取餐號碼</div>
					<div class="number">{{ data.takeNumber }}</div>
				</div>
			</div>

			<div class="take-tip" v-if="canTake === 'take' && !loading">
				餐點完成，請取餐
			</div>
			<div class="take-tip" v-if="canTake === 'del' && !loading">
				你的餐點已被取消
			</div>
			<div class="information">
				<div
					class="text"
					v-if="data.takeTime === null || data.orderStatus !== 1"
				>
					<p>訂單狀態</p>
					<p>{{ status }}</p>
				</div>
				<div class="text">
					<p>取餐方式</p>
					<p>{{ pickUpMethodType }}</p>
				</div>
				<div class="text" v-if="data.address !== ''">
					<p>外送</p>
					<p>{{ data.address }}</p>
				</div>
				<div class="text" v-if="data.name !== ''">
					<p>姓名</p>
					<p>{{ data.name }}</p>
				</div>
				<div class="text" v-if="data.phone !== ''">
					<p>電話</p>
					<p>{{ data.phone }}</p>
				</div>
				<div class="text">
					<p>付款狀態</p>
					<p :style="{ color: paidColor }">{{ paidText }}</p>
				</div>
				<div class="text">
					<p>付款金額</p>
					<p>${{ $stepPrice(data.amountReceived) }}</p>
				</div>
				<div class="text">
					<p>下單時間</p>
					<p>{{ data.createTime }}</p>
				</div>
				<div class="text" v-if="data.takeTime">
					<p v-if="data.address !== ''">外送時間</p>
					<p v-else>預約取餐時間</p>
					<p>{{ data.takeTime }}</p>
				</div>
			</div>
			<ByButton stl="main2" @click="goBack">回我的訂單</ByButton>
			<!--			<div class="button">-->
			<!--				<ByButton stl="main2" @click="goBack">回我的訂單</ByButton>-->
			<!--				<ByButton stl="main1" v-if="screenshotLoad" @click="getScreenshot"-->
			<!--					>一鍵截圖</ByButton-->
			<!--				>-->
			<!--			</div>-->
		</div>
		<ByFooter v-if="!screenshotLoad" />
	</div>
</template>

<script>
import { emitter } from '@/utils/mitt'
import ByButton from '@/components/Button.vue'
import ByFooter from '@/components/Footer.vue'

const orderStatus = {
	1: '新訂單',
	2: '製作中',
	3: '待取餐',
	4: '已完成',
	5: '取消訂單',
	6: '作廢',
	7: '待確認',
}

const payMethod = {
	CASH: '現金',
	LINE_PAY_ONLINE: 'LINE Pay',
	LINE_PAY_OFFLINE: 'LINE Pay',
}

export default {
	components: { ByButton, ByFooter },
	data() {
		return {
			loading: true,
			data: {
				userName: '',
				userPhone: '',
				paid: false,
			},
			screenshotLoad: false,
			refreshKey: Date.now(),
      isFailModal: false
		}
	},
	created() {
		emitter.on('TakeMeal_PAID', this.setPaid)
	},
	mounted() {
		this.getOrderInfo()
	},
	beforeDestroy() {
		emitter.off('TakeMeal_PAID', this.setPaid)
	},
	computed: {
		isOpenFinishModal() {
			return this.$store.state.myOrder.finishOrder
		},
    isOpenFailModal() {
      return this.isFailModal;
    },
		paidText() {
			if (this.data.paidStatus) {
				return '已付款(' + payMethod[this.data.payMethod] + ')'
			}
			return '未付款'
		},
		paidColor() {
			if (this.data.paidStatus) {
				return '#000'
			}
			return '#FF1D00'
		},
		isGuest() {
			return this.$store.getters.isGuest
		},
		onWsWatchOrder() {
			return this.$store.state.socket.onWsWatchOrder
		},
		takeNumber() {
			return this.$store.state.takeMeal.takeNumber
		},
		canTakeNumberList() {
			return this.$store.state.takeMeal.canTakeNumberList
		},
		canTake() {
			if (this.data.orderStatus === 5 || this.data.orderStatus === 6) {
				return 'del'
			} else if (
				this.data.orderStatus === 3 ||
				this.data.orderStatus === 4 ||
				this.canTakeNumberList.includes(this.data.takeNumber)
			) {
				return 'take'
			} else {
				return 'stay'
			}
		},
		status() {
			return orderStatus[this.data.orderStatus]
		},
		pickUpMethodType() {
			if (this.data.address !== '') {
				return '外送'
			}
			if (this.data.tableNumber) {
				const tableNumber = this.data.tableNumber
				if (tableNumber === '') {
					return '內用'
				}
				return '內用(' + tableNumber + '桌)'
			}
			return '自取'
		},
	},
	watch: {
		onWsWatchOrder({ orderId, orderStatus }) {
			if (this.data?.orderId === orderId) {
				this.data.orderStatus = orderStatus
				if (orderStatus >= 7) {
					return
				}
				this.refreshKey = Date.now()

				if (orderStatus > 3) {
					const index = this.$store.state.myOrder.list.findIndex(
						e => e.orderId === orderId,
					)
					if (index > -1) this.$store.state.myOrder.list.splice(index, 1)
					this.$router.push(`/${this.$route.params.random}/MyOrder`)
				}
			}
		},
		canTake(q, o) {
			if (q === 'take' && o !== 'take') {
				this.vibrate()
			}
		},
	},
	methods: {
		closeFinishModal() {
			this.$store.commit('myOrder/SET_FINISH_ORDER', false)
		},
    closeFailModal() {
      this.isFailModal = false;
      this.$router.replace(`/${this.$route.params.random}/Store`)
    },
		vibrate() {
			navigator.vibrate =
				navigator.vibrate ||
				navigator.webkitVibrate ||
				navigator.mozVibrate ||
				navigator.msVibrate
			if (navigator.vibrate) {
				navigator.vibrate(1000)
			}
		},
		goBack() {
			this.$router.push(`/${this.$route.params.random}/MyOrder`)
		},
		getScreenshot() {
			this.screenshotLoad = true
			this.$nextTick(() => {
				this.$html2canvas(this.$refs.orderScreenshotRef, {
					logging: false,
					allowTaint: true,
					scale: window.devicePixelRatio,
					// width: shareContent.clientWidth, //dom 原始宽度
					height: this.$refs.orderScreenshotRef.scrollHeight,
					scrollY: 0,
					scrollX: 0,
					useCORS: true,
					backgroundColor: '#ffffff',
				})
					.then(canvas => {
						let imgUrl = canvas.toDataURL('image/png')
						let tempLink = document.createElement('a')
						tempLink.style.display = 'none'
						tempLink.href = imgUrl
						tempLink.setAttribute('download', this.data.takeNumber)
						if (typeof tempLink.download === 'undefined') {
							tempLink.setAttribute('target', '_blank')
						}
						document.body.appendChild(tempLink)
						tempLink.click()
						document.body.removeChild(tempLink)
						window.URL.revokeObjectURL(imgUrl)
						this.screenshotLoad = false
					})
					.catch(err => {
						console.log(err)
						this.screenshotLoad = false
					})
			})
		},
		setPaid({ id, paidStatus }) {
			if (this.data.orderId === id) {
				this.data.paidStatus = paidStatus
			}
		},
		async getOrderInfo() {
			let id = null
			let hashId = null
			const orderId = this.$route.params.OrderId
			if (!isNaN(Number(orderId))) {
				//舊注單 id為Integer
				id = orderId
			} else if (orderId.length === 36) {
				//新注單 id為UUID
				id = orderId
			} else {
				hashId = orderId
			}
			await this.$api.order
				.getOrderInfo({
					orderId: id,
					hashId: hashId,
				})
				.then(res => {
					const orderStatus = res.data.orderStatus
					if (orderStatus === 8) {
						console.log('金流付款失敗 =>  彈窗+導到菜單')
            this.isFailModal = true;

						return
					}

					this.data = res.data
					this.$store.commit('takeMeal/SET_TAKE_NUMBER', res.data.maxTakeNumber)
					this.$store.commit(
						'takeMeal/SET_TAKE_NUMBER_LIST',
						res.data.canTakeNumberList,
					)
				})
				.catch(err => {
					this.$alert('獲取訂單詳情失敗!', 'danger')
					this.$router.replace(`/${this.$route.params.random}/Store`)
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>

<style lang="sass" scoped>
%title
  font-size: 18px
  font-weight: bold
  margin-top: 10px

.container
  min-height: calc(100vh - 50px)
  position: relative

  .center-page-minus
    padding-bottom: 20px
    text-align: center

    .btn
      border-radius: 50px
      height: 45px
      font-size: 17px
      width: 50%
      margin-top: 20px

    .by-input
      margin-bottom: 15px

    .remind
      margin-top: 39px
      font-size: 16px
      border: 2px solid #ababab
      padding: 7px
      border-radius: 5px
      width: 90%
      color: red

    .information
      margin-top: 15px
      background-color: #f3f3f3
      padding: 0 20px
      border-radius: 10px

    .text
      display: flex
      justify-content: space-between
      padding: 20px 0
      &:not(:nth-last-child(1))
        border-bottom: 1px solid #D8D8D8

      p:nth-child(2)
        font-weight: bold

  ::v-deep .flex
    +fc
    position: absolute
    bottom: 5px
    flex-wrap: wrap
    width: calc(100% - 40px)
    transform: translateX(20px)
    >div
      width: 100%

.num-wrap
  display: flex
  align-items: flex-start
  justify-content: space-between
  width: 300px
  margin: 12px auto 0
.num
  flex: 1
  .title
    @extend %title
  .number
    font-size: 50px
    color: #FF1D00
    font-weight: bold
    margin-top: 10px
    min-height: 50px

.take-tip
  @extend %title
  margin: 36px auto 24px
.button
  display: flex
  align-items: center
  &>:first-child
    margin-right: 15px
</style>
